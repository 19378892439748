<template>
  <div class="page-cu-container">
    <div class="page-cu-main">
      <div class="menu_page" style="padding-top: 20px">
        <div style="display: flex;height: 100%;">
          <div style="width: 400px;padding: 20px;border: 1px solid #eeeeee;">
            <div><a-button type="dashed" style="color: green" @click="createArea"><a-icon type="plus-circle" /> 创建域</a-button></div>
            <a-divider />
            <div>
              <a-tree :showLine="true" :defaultExpandAll="true" :tree-data="menuList" @select="selectTreeNode" :replaceFields="{title: 'name', key: 'id', children: 'menuChildren'}" />
            </div>
          </div>
          <div style="flex: 1;padding: 20px;border: 1px solid #eeeeee;margin-left: 10px">
            <div v-if="currentSelectNode['type'] == '2'">
              <a-button type="dashed" style="color: green" @click="createMenu"><a-icon type="plus-circle" /> 添加子菜单</a-button><a-divider type="vertical" />
              <a-button type="dashed" @click="editArea" style="color: #108ee9;"><a-icon type="edit" /> 编辑域</a-button><a-divider type="vertical" />
              <a-button type="dashed" style="color: #f50;" @click="delArea"><a-icon type="close-circle" /> 删除或</a-button>
            </div>
            <div v-if="currentSelectNode['type'] == '0'">
              <a-button type="dashed" style="color: green" @click="createMenu"><a-icon type="plus-circle" /> 添加子菜单</a-button><a-divider type="vertical" />
              <a-button type="dashed" style="color: #108ee9;" @click="editMenu"><a-icon type="edit" /> 编辑菜单</a-button><a-divider type="vertical" />
              <a-button type="dashed" style="color: #f50;" @click="deleteMenu"><a-icon type="close-circle" /> 删除菜单</a-button><a-divider type="vertical" />
              <a-button type="dashed" style="color: green"><a-icon type="plus-circle" /> 添加按扭</a-button>
            </div>
            <a-divider />
            <a-alert v-if="formVisibleType == 'create-area'" message="当前在执行创建域操作" type="info" show-icon style="margin-bottom: 30px"/>
            <a-alert v-if="formVisibleType == 'edit-area'" message="当前在执行编辑域操作" type="info" show-icon style="margin-bottom: 30px"/>
            <a-alert v-if="formVisibleType == 'create-menu'" message="当前在执行添加菜单操作" type="info" show-icon style="margin-bottom: 30px"/>
            <a-alert v-if="formVisibleType == 'edit-menu'" message="当前在执行编辑菜单操作" type="info" show-icon style="margin-bottom: 30px"/>
            <a-form-model v-if="formVisibleType" ref="formModel" :rules="formModelRules" :model="formModel" :label-col="{ span: 3 }" :wrapper-col="{ span: 12}">
              <a-form-model-item label="名称" prop="name">
                <a-input placeholder="请输入名称" v-model="formModel.name"/>
              </a-form-model-item>
              <a-form-model-item label="前端路由地址" prop="path">
                <a-input placeholder="请输入前端路由地址" v-model="formModel.path"/>
              </a-form-model-item>
              <a-form-model-item label="后端路由地址" prop="grant_urls">
                <a-textarea placeholder="请输入后端路由地址" v-model="formModel.grant_urls" :rows="6"/>
              </a-form-model-item>
              <a-form-model-item label="类型" :defaultValue="formModel.type" prop="type">
                <a-radio-group button-style="solid" v-model="formModel.type">
                  <a-radio-button :value="1" :disabled="formModel.type == '1' ? false : true">按扭</a-radio-button>
                  <a-radio-button :value="0" :disabled="formModel.type == '0' ? false : true">菜单</a-radio-button>
                  <a-radio-button :value="2" :disabled="formModel.type == '2' ? false : true">域</a-radio-button>
                </a-radio-group>
              </a-form-model-item>
              <a-form-model-item label="菜单图标" prop="icon">
                <a-input placeholder="请输入菜单图标" v-model="formModel.icon"/>
              </a-form-model-item>
              <a-form-model-item label="序号" prop="sort">
                <a-input placeholder="请输入序号" v-model="formModel.sort" type="number"/>
              </a-form-model-item>
              <a-form-model-item label="是否分配" prop="allocation">
                <a-radio-group button-style="solid" v-model="formModel.allocation">
                  <a-radio-button value="0">分配</a-radio-button>
                  <a-radio-button value="1">不分配</a-radio-button>
                </a-radio-group>
              </a-form-model-item>
              <a-form-model-item label="前端组件路径" prop="componentPath">
                <a-input placeholder="请输入前端组件路径" v-model="formModel.componentPath"/>
              </a-form-model-item>
              <a-form-model-item :wrapper-col="{ span: 14, offset: 3 }">
                <a-button type="primary" @click="actionData">确定</a-button>
                <a-button style="margin-left: 20px;">重置</a-button>
              </a-form-model-item>
            </a-form-model>
          </div>
        </div>



        <!--<a-button-->
                <!--type="primary"-->
                <!--@click="-->
        <!--() => {-->
          <!--isShowAddAeraDrawer = true;-->
          <!--areaFormMode = 'add';-->
        <!--}-->
      <!--"-->
        <!--&gt;创建域</a-button-->
        <!--&gt;-->
        <!--<a-row>-->
          <!--<a-col span="5" class="panel left">-->
            <!--<a-tree-->
                    <!--v-if="menuList.length > 0"-->
                    <!--v-model="checkedKeys"-->
                    <!--:default-expand-all="true"-->
                    <!--:tree-data="menuList"-->
                    <!--@select="onSelect"-->
                    <!--:replaceFields="replaceFields"-->
            <!--/>-->
          <!--</a-col>-->
          <!--<a-col span="19" class="panel right">-->
            <!--<div class="stack" v-if="activeMenuObject.pid == '0'">-->
              <!--<a-button @click="editArea" type="primary">编辑域</a-button>-->
              <!--<a-button @click="deleteArea" type="danger">删除域</a-button>-->
              <!--<a-button @click="addChildArea" type="dashed">添加子菜单</a-button>-->
            <!--</div>-->
            <!--<div class="stack" v-else>-->
              <!--<a-button @click="editArea" type="primary">编辑菜单</a-button>-->
              <!--<a-button @click="deleteArea" type="danger">删除菜单</a-button>-->
              <!--<a-button @click="addChildArea" type="dashed">添加子菜单</a-button>-->
              <!--<a-button @click="addButton" type="dashed">添加按钮</a-button>-->
            <!--</div>-->
          <!--</a-col>-->
        <!--</a-row>-->
        <!--&lt;!&ndash; 添加域抽屉 &ndash;&gt;-->
        <!--<a-drawer-->
                <!--title="添加域"-->
                <!--width="500"-->
                <!--placement="right"-->
                <!--:body-style="{ paddingBottom: '80px' }"-->
                <!--:visible="isShowAddAeraDrawer"-->
                <!--@close="-->
        <!--() => {-->
          <!--isShowAddAeraDrawer = false;-->
        <!--}-->
      <!--"-->
        <!--&gt;-->
          <!--<a-form :form="areaForm" layout="vertical" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }">-->
            <!--<a-form-item label="名称(name)">-->
              <!--<a-input-->
                      <!--v-decorator="[-->
              <!--'name',-->
              <!--{-->
                <!--rules: [{ required: true }],-->
              <!--},-->
            <!--]"-->
              <!--/>-->
            <!--</a-form-item>-->
            <!--<a-form-item label="前端路由地址(path)">-->
              <!--<a-input-->
                      <!--v-decorator="[-->
              <!--'path',-->
              <!--{-->
                <!--rules: [{ required: true }],-->
              <!--},-->
            <!--]"-->
              <!--/>-->
            <!--</a-form-item>-->
            <!--<a-form-item label="后端路由地址(grant_urls)">-->
              <!--<a-textarea type="" v-decorator="['grant_urls']" />-->
            <!--</a-form-item>-->
            <!--<a-form-item label="类型(type)">-->
              <!--<a-radio-group-->
                      <!--@change="handleMenuTypeChange"-->
                      <!--init-value="0"-->
                      <!--v-decorator="[-->
              <!--'type',-->
              <!--{-->
                <!--rules: [{ required: true }],-->
              <!--},-->
            <!--]"-->
              <!--&gt;-->
                <!--<a-radio-button value="0">-->
                  <!--菜单-->
                <!--</a-radio-button>-->
                <!--<a-radio-button value="1">-->
                  <!--按钮-->
                <!--</a-radio-button>-->
                <!--<a-radio-button value="2">-->
                  <!--域-->
                <!--</a-radio-button>-->
              <!--</a-radio-group>-->
            <!--</a-form-item>-->
            <!--<a-form-item label="菜单图标(icon)">-->
              <!--<a-input-->
                      <!--v-decorator="[-->
              <!--'icon',-->
              <!--{-->
                <!--rules: [{ required: true }],-->
              <!--},-->
            <!--]"-->
              <!--/>-->
            <!--</a-form-item>-->
            <!--<a-form-item label="排序(sort)">-->
              <!--<a-input-->
                      <!--v-decorator="[-->
              <!--'sort',-->
              <!--{-->
                <!--rules: [{ required: true }],-->
              <!--},-->
            <!--]"-->
              <!--/>-->
            <!--</a-form-item>-->
            <!--<a-form-item label="是否分配(allocation)">-->
              <!--<a-radio-group-->
                      <!--init-value="0"-->
                      <!--v-decorator="[-->
              <!--'allocation',-->
              <!--{-->
                <!--rules: [{ required: true }],-->
              <!--},-->
            <!--]"-->
              <!--&gt;-->
                <!--<a-radio-button value="0">-->
                  <!--是-->
                <!--</a-radio-button>-->
                <!--<a-radio-button value="1">-->
                  <!--否-->
                <!--</a-radio-button>-->
              <!--</a-radio-group>-->
            <!--</a-form-item>-->
            <!--<a-form-item label="前端组件路径(componentPath)">-->
              <!--<a-input-->
                      <!--v-decorator="[-->
              <!--'componentPath',-->
              <!--{-->
                <!--rules: [{ required: true }],-->
              <!--},-->
            <!--]"-->
              <!--/>-->
            <!--</a-form-item>-->
          <!--</a-form>-->
          <!--<div class="drawer_footer">-->
            <!--<a-button-->
                    <!--:style="{ marginRight: '8px' }"-->
                    <!--@click="-->
            <!--() => {-->
              <!--isShowAddAeraDrawer = false;-->
            <!--}-->
          <!--"-->
            <!--&gt;-->
              <!--取消-->
            <!--</a-button>-->
            <!--<a-button v-if="areaFormMode == 'add'" type="primary" @click="handleSubmitAreaForm">-->
              <!--保存-->
            <!--</a-button>-->
            <!--<a-button v-else type="primary" @click="handleSubmitAreaForm">-->
              <!--更新-->
            <!--</a-button>-->
          <!--</div>-->
        <!--</a-drawer>-->
      </div>
    </div>
  </div>
</template>

<script>
import * as Api from './api';
import ATextarea from "ant-design-vue/es/input/TextArea";
export default {
  components: {ATextarea},
  data() {
    return {
      // checkedKeys: [],
      // menuList: [],
      // replaceFields: {
      //   children: 'menuChildren',
      //   title: 'name',
      //   key: 'id',
      // },
      // isShowAddAeraDrawer: false,
      // areaForm: this.$form.createForm(this, { name: 'areaForm' }),
      // areaFormMode: 'add',
      // // 当前选择 tree 菜单id
      // activeMenuId: '',
      // // 当前选中 tree 菜单对象
      // activeMenuObject: {},
      // menuType: '0', // 0 菜单、1 按钮、2 域

      menuList: [],
      formModel: {
        name: '',
        path: '',
        grant_urls: '',
        type: '',
        icon: '',
        sort: '',
        allocation: '',
        componentPath: ''
      },
      formModelRules: {
        name: [{ required: true, message: '请输入姓名', trigger: 'change' }],
        path: [{ required: true, message: '请输入前端路由地址', trigger: 'change' }],
        type: [{ required: true, message: '请选择类型', trigger: 'change' }],
        icon: [{ required: true, message: '请输入菜单图标', trigger: 'change' }],
        sort: [{ required: true, message: '请输入排序数', trigger: 'change' }],
        allocation: [{ required: true, message: '请选择是否分配', trigger: 'change' }],
        componentPath: [{ required: true, message: '请输入前端组件路径', trigger: 'change' }]
      },
      actionVisible: false,
      currentSelectNode: '',
      formVisibleType: '',
      submitFlag: true
    };
  },
  mounted() {
    this.getMenuList();
  },
  methods: {

    // 添加菜单
    createMenu() {
      this.resetFormModel();
      this.formVisibleType = 'create-menu';
      this.formModel.type = '0';
    },

    // 编辑菜单
    async editMenu() {
      this.formVisibleType = 'edit-menu';
      let res = await Api.DetailMenu({id: this.currentSelectNode.id});
      this.formModel = res['menu'];
      this.formModel.type = '0';
    },

    // 删除菜单
    deleteMenu() {
      let that = this;
      this.$confirm({
        title: '信息提示',
        okType: 'danger',
        okText: '确定',
        cancelText: '取消',
        content: '您确定要删除吗?',
        async onOk() {
          if (that.submitFlag) {
            that.submitFlag = false;
            let res = await Api.DeleteMenu({id: that.currentSelectNode['id']});
            if ( res && res['code'] == '0') {
              that.deleteNode(that.menuList, that.currentSelectNode['id']);
              that.resetState();
              that.$message.success(res.message);
            } else {
              that.$message.error(res.message);
            }

            that.submitFlag = true;
          }
        },
        onCancel() {},
      });
    },

    // 添加节点
    addNode(arr, id, obj) {
      for (let i = 0, len = arr.length; i < len; i++) {
        let item = arr[i];
        if (item.id === id) {
          if (item.menuChildren) {
            item.menuChildren.push(obj);
          } else {
            item['menuChildren'] = [obj];
          }
          break;
        } else if (item.menuChildren && item.menuChildren.length > 0) {
          this.addNode(item.menuChildren, id, obj)
        }
      }
    },

    // 编辑节点
    editNode(arr, id, obj) {
      for (let i = 0, len = arr.length; i < len; i++) {
        let item = arr[i];
        if (item.id === id) {
          arr[i] = obj;
          break;
        } else if (item.menuChildren && item.menuChildren.length > 0) {
          this.editNode(item.menuChildren, id, obj)
        }
      }
    },

    // 删除节点
    deleteNode(arr, id) {
      for (let i = 0, len = arr.length; i < len; i++) {
        let item = arr[i];
        if (item.id === id) {
          arr = arr.splice(i, 1)
          break;
        } else if (item.menuChildren && item.menuChildren.length > 0) {
          this.deleteNode(item.menuChildren, id)
        }
      }
    },

    // 选择菜单节点
    selectTreeNode(key, event) {
      this.formVisibleType = '';
      this.currentSelectNode = event.node.dataRef
    },

    // 创建域
    createArea() {
      this.currentSelectNode = '';
      this.formVisibleType = 'create-area';
      this.formModel.type = '2';
    },

    // 编辑域
    async editArea() {
      this.formVisibleType = 'edit-area';
      let res = await Api.DetailArea({id: this.currentSelectNode.id});
      this.formModel = res['field'];
      this.formModel.type = '2';
    },

    // 删除域
    delArea() {
      let that = this;
      this.$confirm({
        title: '信息提示',
        okType: 'danger',
        okText: '确定',
        cancelText: '取消',
        content: '您确定要删除吗?',
        async onOk() {
          if (that.submitFlag) {
            that.submitFlag = false;
            let res = await Api.DeleteArea({id: that.currentSelectNode['id']});
            if ( res && res['code'] == '0') {
              that.deleteNode(that.menuList, that.currentSelectNode['id']);
              that.resetState();
              that.$message.success(res.message);
            } else {
              that.$message.error(res.message);
            }

            that.submitFlag = true;
          }
        },
        onCancel() {},
      });
    },

    // 重置当前操作的状态
    resetState() {
      this.resetFormModel();
      this.currentSelectNode = '';
      this.formVisibleType = '';
    },

    // 重置表单
    resetFormModel() {
      for(let key in this.formModel) {
        this.formModel[key] = '';
      }
    },

    // 添加，更新
    actionData() {
      if (this.submitFlag) {
        this.$refs.formModel.validate(async valid => {
          if (valid) {
            let res = null;
            this.submitFlag = false;
            if (this.formVisibleType == 'create-area') { // 创建域
              this.formModel['pid'] = 0;
              res = await Api.SaveArea(this.formModel);
            } else if (this.formVisibleType == 'edit-area'){ // 编辑域
              res = await Api.UpdateArea(this.formModel);
            } else if (this.formVisibleType == 'create-menu' || this.formVisibleType == 'create-btn') {
              this.formModel['pid'] = this.currentSelectNode['id'];
              res = await Api.SaveMenu(this.formModel);
            } else if (this.formVisibleType == 'edit-menu' || this.formVisibleType == 'edit-btn') {
              res = await Api.UpdateMenu(this.formModel);
            }
            if (res && res['code'] == '0') {

              // 创建域
              if (this.formVisibleType == 'create-area') {
                this.menuList.push(res['obj'])
              }
              // 编辑域
              else if (this.formVisibleType == 'edit-area') {
                this.editNode(this.menuList, this.currentSelectNode['id'], res['obj']);
              }
              // 创建菜单
              else if (this.formVisibleType == 'create-menu') {
                console.log(this.menuList)
                this.addNode(this.menuList, this.currentSelectNode['id'], res['obj']);
              }
              // 编辑菜单
              else if (this.formVisibleType == 'edit-menu') {
                this.editNode(this.menuList, this.currentSelectNode['id'], res['obj']);
              }
              this.$message.success(res.message);
              this.actionVisible = false;
              this.resetFormModel();
              this.formVisibleType = '';
            } else {
              this.$message.error(res.message);
            }

            this.submitFlag = true;
          }
        });
      }
    },

    // 菜单列表
    async getMenuList() {
      const res = await Api.GetMenu();
      this.menuList = res.list;
    },
    // onSelect(key, event) {
    //   // console.log(key, event.node.dataRef);
    //   this.activeMenuId = key[0];
    //   this.activeMenuObject = event.node.dataRef;
    // },
    // handleSubmitAreaForm(e) {
    //   e.preventDefault();
    //   this.areaForm.validateFields(async (err, values) => {
    //     if (!err) {
    //       let res;
    //       // if (this.areaFormMode == 'add') {
    //       //   res = await Api.SaveArea({
    //       //     ...values,
    //       //     pid: this.activeMenuId || 0,
    //       //   });
    //       // } else {
    //       //   res = await Api.UpdateArea({
    //       //     id: this.activeMenuId,
    //       //     ...values,
    //       //   });
    //       // }
    //       // if (res.code == '0') {
    //       //   this.$message.success(res.message);
    //       //   this.isShowAddAeraDrawer = false;
    //       //   this.getMenuList();
    //       // }
    //       if (this.menuType == '2') {
    //         // 域类型
    //         if (this.areaFormMode == 'add') {
    //           res = await Api.SaveArea({
    //             ...values,
    //             pid: this.activeMenuId || 0,
    //           });
    //         } else {
    //           res = await Api.UpdateArea({
    //             id: this.activeMenuId,
    //             ...values,
    //           });
    //         }
    //       } else if (this.menuType == '0') {
    //         // 菜单类型
    //         if (this.areaFormMode == 'add') {
    //           res = await Api.SaveMenu({
    //             ...values,
    //             pid: this.activeMenuId || 0,
    //           });
    //         } else {
    //           res = await Api.UpdateMenu({
    //             id: this.activeMenuId,
    //             pid: this.activeMenuObject.pid,
    //             ...values,
    //           });
    //         }
    //       }
    //       if (res.code == '0') {
    //         this.$message.success(res.message);
    //         this.isShowAddAeraDrawer = false;
    //         this.getMenuList();
    //       }
    //     }
    //   });
    // },
    // // 废弃
    // handleSubmitMenuForm(e) {
    //   e.preventDefault();
    //   this.areaForm.validateFields(async (err, values) => {
    //     if (!err) {
    //       let res;
    //       debugger;
    //       if (this.menuType == '2') {
    //         // 域类型
    //         if (this.areaFormMode == 'add') {
    //           res = await Api.SaveArea({
    //             ...values,
    //             pid: this.activeMenuId || 0,
    //           });
    //         } else {
    //           res = await Api.UpdateArea({
    //             id: this.activeMenuId,
    //             ...values,
    //           });
    //         }
    //       } else if (this.menuType == '0') {
    //         // 菜单类型
    //         if (this.areaFormMode == 'add') {
    //           res = await Api.SaveMenu({
    //             ...values,
    //             pid: this.activeMenuId || 0,
    //           });
    //         } else {
    //           res = await Api.UpdateMenu({
    //             id: this.activeMenuId,
    //             ...values,
    //           });
    //         }
    //       }
    //       if (res.code == '0') {
    //         this.$message.success(res.message);
    //         this.isShowAddAeraDrawer = false;
    //         this.getMenuList();
    //       }
    //     }
    //   });
    // },
    // getMenu() {
    //   const type = this.activeMenuObject.type;
    //   const id = this.activeMenuId;
    //   return new Promise(async (resolve, reject) => {
    //     let res;
    //     if (type == '2') {
    //       res = await Api.DetailArea({ id });
    //     } else if (type == '0') {
    //       res = await Api.DetailMenu({ id });
    //     }
    //     resolve(res);
    //   });
    // },
    // // async editArea() {
    // //   this.areaFormMode = 'edit';
    // //   const {
    // //     areaForm: { getFieldsValue, setFieldsValue, resetFields },
    // //   } = this;
    // //
    // //   this.isShowAddAeraDrawer = true;
    // //   const res = await this.getMenu();
    // //   this.$nextTick(() => {
    // //     // const values = getFieldsValue();
    // //     // console.log(values);
    // //     // Object.keys(values).forEach(k => {
    // //     //   values[k] = res.field[k] || res.menu[k];
    // //     // });
    // //     // resetFields();
    // //     this.areaForm.setFieldsValue(res.field || res.menu);
    // //     // setFieldsValue(values);
    // //   });
    // // },
    // deleteArea() {
    //   this.$confirm({
    //     title: '确定删删除该菜单？',
    //     onOk: async () => {
    //       const type = this.activeMenuObject.type;
    //       const id = this.activeMenuId;
    //       console.log(this.activeMenuObject);
    //       let res;
    //       if (type == '2') {
    //         res = await Api.DelArea({ id });
    //         if (res.code == '0') {
    //           this.$message.success(res.message);
    //           this.getMenuList();
    //         }
    //       } else if (type == '0') {
    //         res = await Api.DelMenu({ id });
    //         if (res.code == '0') {
    //           this.$message.success(res.message);
    //           this.getMenuList();
    //         }
    //       }
    //     },
    //   });
    // },
    // addChildArea() {
    //   this.isShowAddAeraDrawer = true;
    //   this.areaFormMode = 'add';
    // },
    // handleMenuTypeChange(e) {
    //   this.menuType = e.target.value;
    // },
    // addButtonArea() {},
    // editMenu() {},
    // deleteMenu() {},
    // addChildMenu() {},
    // addButton() {},
  },
};
</script>

<style lang="less">
.menu_page {
  height: 100%;
  .panel {
    height: 100%;
    margin-top: 20px;
    &:first-child {
      border-right: 1px solid #eaeaea;
    }
    &:last-child {
      padding-left: 20px;
    }
  }
  .ant-row {
    height: 100%;
  }
}
</style>
