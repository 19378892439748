import request from '@/utils/request';

// 菜单相关
export function GetMenu(data) {
  return request({
    url: '/admin/collocation/menu/field/page',
    method: 'GET',
    data,
  });
}

// 保存域

export function SaveArea(data) {
  return request({
    url: `admin/collocation/menu/field/save`,
    method: 'POST',
    data,
  });
}

// 域详情
export function DetailArea(data) {
  return request({
    url: `admin/collocation/menu/field/detail`,
    method: 'GET',
    params: data,
  });
}

// 更新域
export function UpdateArea(data) {
  return request({
    url: `admin/collocation/menu/field/update`,
    method: 'POST',
    data,
  });
}

// 删除域
export function DeleteArea(data) {
  return request({
    url: `admin/collocation/menu/field/delete`,
    method: 'GET',
    params: data,
  });
}

// 保存菜单
export function SaveMenu(data) {
  return request({
    url: `/admin/collocation/menu/menu/save`,
    method: 'POST',
    data,
  });
}

// 更新菜单
export function UpdateMenu(data) {
  return request({
    url: `/admin/collocation/menu/menu/update`,
    method: 'POST',
    data,
  });
}

// 删除菜单
export function DeleteMenu(data) {
  return request({
    url: `/admin/collocation/menu/menu/delete`,
    method: 'GET',
    params: data,
  });
}

// 菜单详情
export function DetailMenu(data) {
  return request({
    url: `/admin/collocation/menu/menu/detail`,
    method: 'GET',
    params: data,
  });
}
